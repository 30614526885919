.help-siret-popup-tooltip {
  float: right;
}
.help-siret-popup-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.help-siret-popup-tooltip .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: white;
  margin: 0;
  font-size: 16px;
  margin-top: 3px;
  font-family: Noto Sans JP,Noto,Lato,Roboto,sans-serif;
  font-weight: 400;
  line-height: 1.66;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 10%), 0 24px 38px 3px rgb(0 0 0 / 3%), 0 9px 46px 8px rgb(0 0 0 / 2%);
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
  top: -5px;
  right: 105%;
}

.help-siret-popup-tooltip:hover .tooltiptext {
  visibility: visible;
}
